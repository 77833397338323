(function ($) {
  $('.Tabs').each(function(i, tab) {
      var tabRoot = $(this);
      var tabTrigger = '.TabTrigger';
      var tabContent = '.TabContent';
      var findTabTrigger = tabRoot.find(tabTrigger);
      var findTabContent = tabRoot.find(tabContent);

      findTabTrigger.attr('aria-selected', false);
      findTabContent.attr('aria-expanded', false);

      findTabTrigger.eq(0).addClass('__active').attr('aria-selected', true);
      findTabContent.eq(0).addClass('__active').attr('aria-expanded', true);

      findTabTrigger.click(function(e) {
          e.preventDefault();
          var dataSet = $(this).data('set');

          tabRoot.find(tabTrigger + '.__active').attr('aria-selected', false).removeClass('__active');
          $(this).attr('aria-selected', true).addClass('__active');

          tabRoot.find(tabContent + '.__active').attr('aria-expanded', false).removeClass('__active');
          tabRoot.find(tabContent + '[data-set="' + dataSet + '"]').attr('aria-expanded', true).addClass('__active');
          
      });

      $(tabTrigger).on('click', function() {
          if ($(this).hasClass('__active')) {
            $(this).addClass('__is-open');
          }
          else {
              $(this).removeClass('__is-open');
          }
        });
  });
})(jQuery);

