(function ($) {
    $('input[name="s"]').on('keyup', populateSearchResults);
    $('input[name="search"]').on('keyup', populateSearchResults);

    function populateSearchResults() {
        var search = $(this).val();
        var input = $(this);
        var results = input.closest('form').find('ul.search-results');

        if (search.length > 3 && ajaxurl) {
            url = ajaxurl + '?action=search_posts&s=' + search;
            $.ajax({
                method: 'GET',
                url: url,
                success: function (response) {

                    results.html("");
                    if (!response.success) {
                        return;
                    }

                    $.each(response.data, function (index, value) {
                        results.append(
                            '<li>' +
                            '<a href="' + value.url + '">' +
                            '<span class="title">' + value.title + '</span>' +
                            '</a>' +
                            '</li>'
                        );
                    });
                }
            });
        } else {
            results.html("");
        }
    }
})(jQuery);