(function ($) {
    function copyBillingInfo(event) {
        event.preventDefault();
        var target = $(event.target).data('target');

        var first_name = $('input[name="billing_first_name"]').val();
        var last_name = $('input[name="billing_last_name"]').val();
        var email = $('input[name="billing_email"]').val();
        var phone = $('input[name="billing_phone"]').val();
        var company = $('input[name="billing_company"]').val();

        $('#' + target + '_first_name').val(first_name);
        $('#' + target + '_last_name').val(last_name);
        $('#' + target + '_email').val(email);
        $('#' + target + '_phone').val(phone);
        $('#' + target + '_company').val(company);
    }

    function clearDelegateInfo(event) {
        event.preventDefault();
        var target = $(event.target).data('target');

        $('#' + target + '_first_name').val('');
        $('#' + target + '_last_name').val('');
        $('#' + target + '_email').val('');
        $('#' + target + '_phone').val('');
    }

    function toggleDelegate(event) {
        event.preventDefault();
        var target = $(event.target).data('target');
        $('#' + target + '_wrap').toggle(200);
        $(event.target).find('.dashicons').toggleClass('dashicons-arrow-right dashicons-arrow-down')
    }

    window.copyBillingInfo = copyBillingInfo;
    window.clearDelegateInfo = clearDelegateInfo;
    window.toggleDelegate = toggleDelegate;
})(jQuery);
