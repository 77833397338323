// /* searchtoggle */
(function ($) {
    var searchToggle = $('.searchToggleTrigger');
    var searchBar = $('.searchBarElement');
    var searchBarFocusElement = $('.searchBarFocus');
    var wrapper = $('.wrapper');
    var searchBarHidden = true;
    var searchReset = $('.searchReset');

    searchToggle.click(function(event) {
        event.preventDefault();
        searchBarHidden = !searchBarHidden;
        // if (window.innerWidth < 992) {
            $(this).toggleClass('__active');
            searchBar.toggleClass('__active');
        // }
        wrapper.toggleClass('__searchactive');
        searchBar.attr('aria-hidden',searchBarHidden);
        if (searchBarHidden) {
            searchBar.find('*[tabindex=0]').attr('tabindex', '-1');
            searchToggle.focus();
        } else {
            searchBar.find('*[tabindex=-1]').attr('tabindex', '0');
            searchBarFocusElement.focus();
        }
    });

    searchReset.click(function(event) {
        searchBarHidden = true;
        if (window.innerWidth < 992) {
            searchToggle.removeClass('__active');
            searchBar.removeClass('__active');
        }
        wrapper.removeClass('__searchactive');
        searchBar.attr('aria-hidden',searchBarHidden);
    });
})(jQuery);
/* searchtoggle */
// $(function() {
//     var searchToggle = $('.searchToggleTrigger');
//     var searchBar = $('.searchBarElement');
//     var searchBarFocusElement = $('.searchBarFocus');
//     var wrapper = $('main');
//     var searchBarHidden = true;

//     searchToggle.click(function(event) {
//         event.preventDefault();
//         searchBarHidden = !searchBarHidden;
//         $(this).toggleClass('__active');
//         searchBar.toggleClass('__active');
//         searchBar.slideToggle();
//         // wrapper.toggleClass('__searchactive');
//         searchBar.attr('aria-hidden',searchBarHidden);
//         if (searchBarHidden) {
//             searchBar.attr('tabindex', '-1');
//             searchBar.attr('aria-expanded', 'false');
//             searchToggle.focus();
//         } else {
//             searchBar.attr('tabindex', '0');
//             searchBar.attr('aria-expanded', 'true');
//             searchBarFocusElement.focus();
//         }
//     });
// });
