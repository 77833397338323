/* accordion */
(function ($) {
    $('.Accordion').each(function(i, accordion) {
        var accordionTrigger = '.AccordionTrigger';
        var accordionContent = '.AccordionContent';
        var findAccordionTrigger = $(this).find(accordionTrigger);
        var findAccordionContent = $(this).find(accordionContent);

        // Apply ARIA and display values on load
        if (findAccordionTrigger.hasClass('__active')) {
            findAccordionTrigger.attr('aria-expanded', true);
            findAccordionContent.show();
            findAccordionContent.attr('aria-hidden', false);
        } else {
            findAccordionTrigger.attr('aria-expanded', false);
            findAccordionContent.hide();
            findAccordionContent.attr('aria-hidden', true);
        }

        // On click of accordion trigger
        findAccordionTrigger.click(function(e) {
            e.preventDefault();
            /* close an open accordion */
            if ($(this).hasClass('__active')) {
                $(this).attr('aria-expanded', false);
                $(this).removeClass('__active');
                findAccordionContent.slideUp();
                findAccordionContent.attr('aria-hidden', true);
            } /* open a closed accordion */ else {
                $(this).attr('aria-expanded', true);
                $(this).addClass('__active');
                findAccordionContent.slideDown();
                findAccordionContent.attr('aria-hidden', false);
            }
        });
    });
})(jQuery);