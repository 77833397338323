/* share social */
(function ($) {
    var toggle = $('.socialShareToggle');

    toggle.click(function() {
        event.preventDefault();
        $(this)
            .siblings('.socialShareContent')
            .toggleClass('__active');
    });
})(jQuery);
