/* sidemenu */
(function ($) {
    var toggle = $('.sidemenuToggle');
    toggle.click(function() {
        event.preventDefault();
        $(this)
            .parent()
            .toggleClass('__active');
        $(this)
            .siblings()
            .slideToggle();
    });

    //tab mobile menu //
    var tabs = $('.tabMenu');
    var menuTrigger = tabs.children('.sidemenuToggle');
    var break_point = 768;
    var browser_width = window.innerWidth;
    $(document).ready(function(){
        if (browser_width <= break_point) {
            menuTrigger.siblings()
            .slideToggle();
        }
    })
})(jQuery);

