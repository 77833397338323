/* Countdown */
(function ($) {
    function updateTimer() {
        // console.log('updateTimer');
        const element = document.getElementById('timer');
        // console.log(element);
        const endTime = new Date(element.dataset.endtime);
        const now = new Date();
        const diff = endTime - now;
    
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(diff / (1000 * 60 * 60));
        const mins = Math.floor(diff / (1000 * 60));
        const secs = Math.floor(diff / 1000);
    
        const d = days >= 10 ? days : `0${days}`;
        const h = hours - days * 24 >= 10 ? hours - days * 24 : `0${hours - days * 24}`;
        const m = mins - hours * 60 >= 10 ? mins - hours * 60 : `0${mins - hours * 60}`;
        const s = secs - mins * 60 >= 10 ? secs - mins * 60 : `0${secs - mins * 60}`;

        element.innerHTML =
            '<span>' + d + '<span>:</span></span>' +
            '<span>' + h + '<span>:</span></span>' +
            '<span>' + m + '<span>:</span></span>' +
            '<span>' + s + '</span>';
    }
    if (document.getElementById("timer")) {
        setInterval(updateTimer, 1000);
    }
})(jQuery);